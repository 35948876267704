












































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/DriverScoreboardController'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { DRIVER_PAGINATION, Utils } from '@/app/infrastructures/misc'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import ExportIcon from '@/app/ui/assets/export_icon.vue'
import dayjs from 'dayjs'

interface Parameter {
  page: number
  perPage: number
  dateFrom?: string
  dateTo?: string
  q?: string
  courierType?: string
  sort?: string
}

interface Dropdown {
  label: string
  value: string
}

@Component({
  components: {
    Button,
    LoadingOverlay,
    TextInput,
    DateTimePicker,
    DropdownSelect,
    DataTable,
    PaginationNav,
    ExportIcon,
  },
})
export default class DriverListPage extends Vue {
  controller = controller

  parameters: Parameter = {
    page: 1,
    perPage: DRIVER_PAGINATION,
  }

  headers = ['No.', 'Nama', 'Poin', 'Kurir Type', 'ID Kurir', 'Nomor HP']

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || DRIVER_PAGINATION,
        dateFrom:
          queries.dateFrom ||
          dayjs()
            .subtract(2, 'days')
            .format('YYYY-MM-DD'),
        dateTo:
          queries.dateTo ||
          dayjs()
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
        q: queries.q || undefined,
        courierType: queries.courierType || undefined,
        sort: queries.sort || 'score-desc',
      }
    }

    this.fetchDriverList()
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  get driverDataTable(): Array<string | number | undefined>[] {
    return controller.driverData.map(driver => [
      '',
      driver.fullName,
      driver.score,
      driver.courierType,
      driver.id,
      Utils.countryIndonesiaPhoneNumber(driver.phoneNumber as string, true),
    ])
  }

  get params(): Record<string, string | number> {
    return {
      ...this.parameters,
    }
  }

  get selectedDriverType(): Dropdown | null {
    if (!this.parameters.courierType) return null

    return this.driverTypeOptions.find(
      type => type.value === this.parameters.courierType
    ) as Dropdown
  }

  set selectedDriverType(type: Dropdown | null) {
    this.parameters.courierType = type?.value
  }

  private search = Utils.debounce(() => {
    this.refetchData()
  }, 500)

  get driverTypeOptions(): Dropdown[] {
    return controller.constant.driverType
  }

  get selectedSort(): Dropdown | null {
    if (!this.parameters.sort) return null

    return this.sortOptions.find(
      sort => sort.value === this.parameters.sort
    ) as Dropdown
  }

  set selectedSort(sort: Dropdown | null) {
    this.parameters.sort = sort?.value
  }

  get sortOptions(): Dropdown[] {
    return controller.constant.sort
  }

  get sortPlaceholder(): string {
    return (
      this.sortOptions.find(sort => sort.value === this.parameters.sort)
        ?.label || ''
    )
  }

  private refetchData() {
    this.fetchDriverList(true)
  }

  private changeDate() {
    const dateFrom = dayjs(this.parameters.dateFrom).startOf('day')
    const dateTo = dayjs(this.parameters.dateTo).endOf('day')

    if (dateTo.diff(dateFrom, 'days') > 30) {
      Vue.notify({
        title: 'Error',
        text: 'Max range date is 30 days',
        type: 'error',
        duration: 5000,
      })

      return
    }

    this.refetchData()
  }

  private resetFilter() {
    this.parameters.page = 1
    this.parameters.q = undefined
    this.parameters.courierType = undefined
    this.parameters.dateFrom = dayjs()
      .subtract(2, 'days')
      .format('YYYY-MM-DD')
    this.parameters.dateTo = dayjs()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')

    this.refetchData()
  }

  private formatParams(parameters: Parameter) {
    let dateTo = undefined
    if (parameters.dateTo) {
      dateTo = dayjs(this.parameters.dateTo)
      if (!dateTo.isSame(new Date(), 'day')) {
        dateTo = dateTo.endOf('day')
      } else {
        dateTo = dayjs().subtract(1, 'minutes')
      }
      dateTo = dateTo.toISOString()
    }

    let sortKey = undefined
    let sortValue = undefined

    if (parameters.sort !== undefined && parameters.sort !== null) {
      const sort = parameters.sort.split('-')

      sortKey = sort[0]
      sortValue = sort[1]
    }

    return {
      ...parameters,
      q: this.replacePhoneNumber(parameters.q || ''),
      dateFrom: parameters.dateFrom
        ? dayjs(parameters.dateFrom)
            .startOf('day')
            .toISOString()
        : undefined,
      dateTo,
      sortKey,
      sortValue,
    }
  }

  private fetchDriverList(resetPage = false): void {
    if (resetPage) this.parameters.page = 1

    this.controller.getDriverList(this.formatParams(this.parameters))
  }

  private openDriver(row: Array<string | number>) {
    this.$router.push({
      name: 'DriverScoreReport',
      params: {
        courierId: row[4].toString(),
      },
      query: {
        dateFrom: this.parameters.dateFrom,
        dateTo: this.parameters.dateTo,
      },
    })
  }

  private replacePhoneNumber(text: string): string {
    if (/^08([0-9]+)$/.test(text)) {
      return Utils.countryIndonesiaPhoneNumber(text).trim()
    }

    return text
  }

  private handleExport() {
    controller.exportDriverScoreReport(this.formatParams(this.parameters))
  }
}
